import React, { FC, useContext } from "react";
import { Box, Flex, Link, system } from "flicket-ui";
import styled from "styled-components";
import { marketingUrl } from "~config";
import { UserContext } from "~context";
import { useOrganization } from "~hooks";

const StyledFooter = styled(Box)`
  justify-content: center;

  width: 100%;

  padding: 32px 32px 0;

  background-color: ${(p) => p.theme.colors.N100};

  color: ${(p) => p.theme.colors.N600};
  font-size: ${(p) => p.theme.fontSizes[3]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 150%;
  letter-spacing: -0.165px;

  > div {
    display: flex;
    justify-content: space-between;

    position: relative;

    width: 100%;
    max-width: 1184px;

    padding: 24px 0 64px 0;

    &::before {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      height: 1px;

      background-color: ${(p) => p.theme.colors.N300};
    }
  }

  ${system}
`;

interface FooterProps {
  hasFullPageBackground?: boolean;
}

export const Footer: FC<FooterProps> = ({ hasFullPageBackground }) => {
  const { user } = useContext(UserContext);
  const { organization } = useOrganization();

  const footerStyles = (() => {
    if (!hasFullPageBackground) return {};
    return {
      background: "transparent",
      position: "absolute",
      left: 0,
      bottom: 0,
      right: 0,
    };
  })();

  return (
    // @ts-expect-error no overload error
    <StyledFooter d={{ _: "none", md: "flex" }} {...footerStyles}>
      <div>
        <Flex>
          <Box>
            <Link mr={8} to="/terms-and-conditions">
              Terms &amp; Conditions
            </Link>
            <Link mr={8} to="/privacy-policy">
              Privacy Policy
            </Link>
            <Link mr={8} to={user ? "/data-access" : "/login"}>
              Data Access &amp; Deletion
            </Link>
            <a
              target="_blank"
              href={
                organization?.helpLink ??
                "https://flicketfaqs.groovehq.com/help"
              }
              rel="noopener noreferrer"
            >
              Help
            </a>
          </Box>
        </Flex>
        <Link href={marketingUrl}>Powered by Flicket</Link>
      </div>
    </StyledFooter>
  );
};
