import { useEffect } from "react";
import { hotjar } from "react-hotjar";
declare interface DataLayerWindow extends Window {
  fbq: any;
}
declare const window: DataLayerWindow;

interface UseThirdpartyScriptsProps {
  gtmId: string;
  pixelId: string;
}

const ENABLE_TRACKING_SCRIPTS =
  process.env.NODE_ENV === "production" && typeof window !== "undefined";
const HOTJAR_ID = process.env.HOTJAR_PRODUCTION_ID;

export const useThirdpartyScripts = ({
  gtmId,
  pixelId,
}: UseThirdpartyScriptsProps) => {
  useEffect(() => {
    if (ENABLE_TRACKING_SCRIPTS && HOTJAR_ID) {
      hotjar.initialize((HOTJAR_ID as unknown) as number, 6);
    }
  }, []);

  useEffect(() => {
    if (ENABLE_TRACKING_SCRIPTS && !!gtmId) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        const f = d.getElementsByTagName(s)[0],
          j: any = d.createElement(s),
          dl = l !== "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.defer = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", gtmId);
    }
  }, []);

  useEffect(() => {
    if (ENABLE_TRACKING_SCRIPTS) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        const f = d.getElementsByTagName(s)[0],
          j: any = d.createElement(s),
          dl = l !== "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.defer = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-WXVNCRX");
    }
  }, []);

  useEffect(() => {
    if (ENABLE_TRACKING_SCRIPTS && typeof window !== "undefined") {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        const f = d.getElementsByTagName(s)[0],
          j: any = d.createElement(s),
          dl = l !== "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.defer = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-MSK3NHN");
    }
  }, []);

  useEffect(() => {
    if (ENABLE_TRACKING_SCRIPTS && !!pixelId) {
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function (...args) {
          n.callMethod ? n.callMethod(...args) : n.queue.push(args);
        };
        if (!(f as any)._fbq) (f as any)._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.defer = true;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      window.fbq("init", pixelId);
      window.fbq("track", "PageView");
    }
  }, []);
};
