import {
  createContext,
  ReactNode,
  useReducer,
  useState,
  SetStateAction,
  Dispatch,
} from "react";

import get from "lodash/get";
import intersection from "lodash/intersection";

import { Role, UserFragment } from "~graphql/sdk";

import * as track from "~features/analytics/track";

export enum UserActionTypes {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  AUTHENTICATE_TWO_FACTOR = "AUTHENTICATE_TWO_FACTOR",
}

type UserActions =
  | { type: UserActionTypes.LOGOUT }
  | { type: UserActionTypes.LOGIN; payload: UserFragment }
  | { type: UserActionTypes.AUTHENTICATE_TWO_FACTOR };

interface UserState {
  user: UserFragment;
  isInitialising: boolean;
  isLoggedIn: boolean;
  hasLoaded: boolean;
  isTwoFactorAuthenticated: boolean;
}

interface UserContextProps extends UserState {
  dispatch: (input: UserActions) => void;
  isAuth: boolean;
  isAdmin: boolean;
  isPOS: boolean;
  hasCheckedLogin: boolean;
  setHasCheckedLogin: Dispatch<SetStateAction<boolean>>;
  isSalesOutlet: boolean;
  isEventManager: boolean;
}

interface Props {
  children: ReactNode;
}

const initialState: UserState = {
  isInitialising: true,
  isLoggedIn: false,
  user: undefined,
  hasLoaded: false,
  isTwoFactorAuthenticated: false,
};

const hasRoles = (
  userRoles: UserFragment["roles"],
  roles: UserFragment["roles"]
) => Boolean(get(intersection(userRoles ?? [], roles), "length"));

const getIsTwoFactorAuthenticatedInLocalStorage = () => {
  return !!localStorage.getItem("isTwoFactorAuthenticated");
};

const setIsTwoFactorAuthenticatedInLocalStorage = () => {
  localStorage.setItem("isTwoFactorAuthenticated", "true");
};

const removeIsTwoFactorAuthenticatedInLocalStorage = () => {
  localStorage.removeItem("isTwoFactorAuthenticated");
};

function reducer(state: UserState, action: UserActions) {
  switch (action.type) {
    case UserActionTypes.LOGOUT:
      removeIsTwoFactorAuthenticatedInLocalStorage();

      track.logout();

      return {
        ...state,
        isInitialising: false,
        user: undefined,
        isLoggedIn: false,
        hasLoaded: true,
        isTwoFactorAuthenticated: false,
      };
    case UserActionTypes.LOGIN:
      track.login(action.payload);

      return {
        ...state,
        isInitialising: false,
        user: action.payload,
        isLoggedIn: true,
        hasLoaded: true,
        isTwoFactorAuthenticated: getIsTwoFactorAuthenticatedInLocalStorage(),
      };
    case UserActionTypes.AUTHENTICATE_TWO_FACTOR:
      setIsTwoFactorAuthenticatedInLocalStorage();
      return {
        ...state,
        isInitialising: false,
        isTwoFactorAuthenticated: true,
      };
    default:
  }
}

const UserContext = createContext<UserContextProps>(undefined);

const UserProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [hasCheckedLogin, setHasCheckedLogin] = useState(false);

  return (
    <UserContext.Provider
      value={{
        ...state,
        hasCheckedLogin,
        setHasCheckedLogin,
        dispatch,
        isAuth: !!state?.user,
        isPOS: hasRoles(state?.user?.roles, [Role.PosOperator]),
        isAdmin: hasRoles(state?.user?.roles, [
          Role.Admin,
          Role.Superadmin,
          Role.Sales,
        ]),
        isSalesOutlet: hasRoles(state?.user?.roles, [Role.SalesOutlet]),
        isEventManager: hasRoles(state?.user?.roles, [Role.EventManager]),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
