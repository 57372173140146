import { useEffect, useState } from 'react';

import { fbAppId } from '~config';

declare interface FacebookWindow extends Window {
  fbAsyncInit(): void;
  FB: any;
}
declare const window: FacebookWindow;

interface FacebookLoginHookArgs {
  onSuccess(accessToken: string): void;
}

interface FacebookLoginHook {
  isFacebookLoaded: boolean;
  isFacebookLoading: boolean;
  handleFacebookLogin(): void;
}

export const useFacebookLogin = ({
  onSuccess,
}: FacebookLoginHookArgs): FacebookLoginHook => {
  const [isFacebookLoaded, setFacebookLoaded] = useState(false);
  const [isFacebookLoading, setFacebookLoading] = useState(false);

  useEffect(() => {
    if (window.FB) {
      setFacebookLoaded(true);
      return;
    }

    window.fbAsyncInit = () => {
      window.FB.init({
        version: `v8.0`,
        appId: fbAppId,
        cookie: true,
        xfbml: false,
      });
      setFacebookLoaded(true);
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);

  const handleFacebookLogin = () => {
    if (!isFacebookLoaded || isFacebookLoading) {
      return;
    }

    setFacebookLoading(true);

    window.FB.login(
      (response) => {
        if (response?.status === 'connected') {
          onSuccess(response?.authResponse?.accessToken);
        }

        setFacebookLoading(false);
      },
      { scope: 'email' },
    );
  };

  return {
    isFacebookLoaded,
    isFacebookLoading,
    handleFacebookLogin,
  };
};
