import { Ticket } from "~graphql/sdk";

export const formatSeatLocation = (
  seatZone?: Ticket["seatZone"],
  seatSection?: Ticket["seatSection"],
  seatLabel?: Ticket["seatLabel"]
) => {
  const split = seatLabel?.split("-");
  let label = seatLabel;

  if (split?.length > 2) {
    label = `Row ${split[1].trim()}, Seat ${split[2].trim()}`;
  } else if (split?.length === 2) {
    label = `Seat ${split[1].trim()}`;
  }

  return `${seatSection ? `${seatSection}` : ""}${
    label ? `${seatZone && seatSection ? ", " : ""}${label}` : ""
  }`;
};
